import { useEffect, useLayoutEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import { useUser } from '@/hooks/useUser';
import ReactGA from 'react-ga';
import { Sidebar } from './Sidebar';
import { BootstrapStyles } from '@/components/layout/Bootstrap';
import { Toaster } from '../ui/toaster';

const Layout = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  const user = useUser();
  useLayoutEffect(() => {
    if (user === null) {
      console.log('user is null');
      window.location.href = '/';
    }
    if (user?.signedIn === false) {
      window.location.href = '/';
    }
  }, [user]);

  if (!user) return <div>Loading...</div>;

  return (
    <div className="_flex _flex-col _w-full _min-h-screen ">
      <BootstrapStyles />
      <Navbar />
      <div className="_relative _flex _grow _w-full _h-full">
        <Sidebar />
        <div className="_px-4 _bg-white _pt-2 _flex _flex-col _grow">
          <div className="_grow">
            <Outlet />
          </div>
        </div>
      </div>
	  <Toaster />
    </div>
  );
};

export default Layout;
