import 'react-calendar/dist/Calendar.css';
import './App.css';
import { OnlineStatusProvider } from '@/_helpers/use_online_status_hook';
import ReactGA from 'react-ga';
import AllRoutes from '@/routes';
ReactGA.initialize(import.meta.env.VITE_GOOGLE_TAG);

const App = () => {
  return (
    <OnlineStatusProvider>
      <AllRoutes />
    </OnlineStatusProvider>
  );
};

export default App;
